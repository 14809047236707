.progressWrapper {
  width: 100%;
  padding: 0 var(--ds-spacing-4xs);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: var(--ds-spacing-4xs);
  /* prevent device native swipe, pinch to zoom etc... interactions when touch event starts on the progress bar */
  touch-action: none;
}

.time {
  width: 70px;
  color: var(--ds-color-text-inverse);
  line-height: 0;

  @media (--ds-breakpoint-lg) {
    width: 80px;
  }
}

.progressBar {
  position: relative;
  height: 40px;
  cursor: pointer;
  /* fill the space between the two text labels */
  flex-grow: 1;

  &:hover,
  &:has(:focus-visible) {
    .progressBackground,
    .progress,
    .buffer {
      height: var(--ds-size-3xs);
    }

    .slider {
      /* must be a multiple of 10 to prevent slider resizing on sub-pixels when moving */
      width: 20px;
      height: 20px;
    }
  }
}

.progressBackground,
.progress,
.buffer {
  position: absolute;
  height: var(--ds-size-4xs);
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: var(--ds-border-radius-round);
  transition: height 0.1s;
}

.progressBackground {
  width: 100%;
  background-color: var(--ds-color-layer-transparent-light-3);
}

.progress {
  background-color: var(--ds-color-layer-default);
  width: var(--progress);
}

.buffer {
  background-color: var(--ds-color-layer-transparent-light-3);
  width: var(--buffer);
}

.slider {
  position: absolute;
  top: 50%;
  left: var(--progress);
  transform: translate(-50%, -50%);
  /* must be a multiple of 10 to prevent slider resizing on sub-pixels when moving */
  height: 10px;
  width: 10px;
  background-color: var(--ds-color-layer-default);
  border-radius: var(--ds-border-radius-round);
  cursor: pointer;
  transition: width 0.1s, height 0.1s;
}

.preview {
  position: absolute;
  bottom: 100%;
  transform: translateX(-50%);
  aspect-ratio: 16 / 9;
  width: 33.3%;
  background: var(--ds-color-layer-transparent-dark-3);
  border-radius: var(--ds-border-radius-md);
  display: none;
  pointer-events: none;
}
