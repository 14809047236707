.playPauseRow {
  position: absolute;
  height: fit-content;
  width: fit-content;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--ds-spacing-3xs);
}

/* The play button and the buffering spinner are slightly different dimensions, so we use a wrapper to remove layout shift when toggling between them */
.playAndBufferWrapper {
  width: 72px;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
}
