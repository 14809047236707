.kisWrapper {
  color: var(--text-color);
  display: grid;
  grid-template-columns: repeat(2, min-content) 1fr;
  grid-template-rows: repeat(2, auto);
  column-gap: var(--ds-spacing-xs);
  width: 100%;
  max-width: 343px; /* no appropriate Skrøll token available */

  @media (--ds-breakpoint-sm) {
    &.vertical {
      display: flex;
      flex-direction: column;
      max-width: 276px; /* no appropriate Skrøll token available */

      & li:last-child {
        border-bottom: none;
      }
    }
  }
}

.logo {
  display: flex;
  justify-content: flex-start;
  gap: var(--ds-spacing-4xs);
  margin-bottom: var(--ds-spacing-3xs);
  grid-column: 1 / -1;
}

.ageRangeContainer {
  display: grid;
  justify-content: start;
  align-items: center;
  grid-column: 1 / 2;
  grid-row: 2 / 3;

  &.showLogo {
    grid-row: 3 / 4;
  }

  @media (--ds-breakpoint-sm) {
    display: inline;

    &.vertical {
      border-bottom: solid var(--divider-color) 1.5px;
      padding-bottom: var(--ds-spacing-3xs);
    }
  }
}

.ageRangeMobile {
  margin: 0;

  @media (--ds-breakpoint-sm) {
    display: none;
  }
}

.ageRangeDesktop {
  display: none;

  @media (--ds-breakpoint-sm) {
    display: inline;
  }
}

.productTitleContainer {
  grid-column: 1 / 4;
  margin-bottom: var(--ds-spacing-3xs);

  &.showLogo {
    grid-row: 2 / 3;
  }

  @media (--ds-breakpoint-sm) {
    &.vertical {
      border-bottom: solid var(--divider-color) 1.5px;
      margin-bottom: 0;
      padding: var(--ds-spacing-3xs) 0;
    }
  }
}

.productCodeContainer {
  display: grid;
  place-items: center;
  grid-column: 2 / 3;
  padding: 0 var(--ds-spacing-xs);
  border-left: solid var(--divider-color) 1.5px;
  border-right: solid var(--divider-color) 1.5px;

  &.showLogo {
    grid-row: 3 / 4;
  }

  @media (--ds-breakpoint-sm) {
    &.vertical {
      justify-content: start;
      border-bottom: solid var(--divider-color) 1.5px;
      border-left: 0;
      border-right: 0;
      padding: var(--ds-spacing-3xs) 0;
    }
  }
}

.productCode {
  margin: 0;
}

.pieceCountContainer {
  display: grid;
  grid-column: 3 / 4;

  &.showLogo {
    grid-row: 3 / 4;
  }

  @media (--ds-breakpoint-sm) {
    &.vertical {
      display: inline;
      border-bottom: solid var(--divider-color) 1.5px;
      padding: var(--ds-spacing-3xs) 0;
    }
  }
}

.pieceCountMobile {
  margin: 0;

  @media (--ds-breakpoint-sm) {
    display: none;
  }
}

.pieceCountDesktop {
  display: none;

  @media (--ds-breakpoint-sm) {
    &.vertical {
      display: inline;
      border-left: 0;
      padding-left: 0;
    }

    &.horizontal {
      display: flex;
      align-items: center;
      gap: var(--ds-spacing-4xs);
      margin: 0;
    }
  }
}

.priceContainer {
  grid-column: 1 / 4;
  grid-row: 3 / 4;
  padding-top: var(--ds-spacing-3xs);

  &.showLogo {
    grid-row: 4 / 5;
  }
}

.price {
  margin: 0;
}
