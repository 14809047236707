.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  /* top/left and transform are required to center the controls in full screen mode */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 var(--ds-spacing-3xs);
  cursor: pointer;
  display: flex;
  flex-direction: column;

  &.playIcon {
    justify-content: center;
    align-items: center;
  }

  &.controls {
    background: var(--ds-color-layer-transparent-dark-3);
    opacity: 0;
    transition: opacity 0.3s;
    align-items: center;
    justify-content: flex-end;

    &.controlsVisible {
      opacity: 1;
    }
  }

  @media (pointer: fine) {
    & button {
      transition: transform 0.1s ease-in-out;
      transform: scale(1);

      &:hover {
        transform: scale(1.2);
      }
    }
  }
}
