.dialog {
  position: fixed;
  padding: 0;
  border: none;

  &[open]::backdrop {
    background: var(--ds-color-layer-transparent-dark-2);
  }
}

.closeButton {
  position: absolute;
  top: var(--ds-spacing-2xs);
  right: var(--ds-spacing-2xs);
  z-index: 1;

  @media (pointer: fine) {
    & button {
      transition: transform 0.1s ease-in-out;
      transform: scale(1);

      &:hover {
        transform: scale(1.2);
      }
    }
  }
}
