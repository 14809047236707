.bannerWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}

.imageWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.videoContentWrapper {
  position: absolute;
  z-index: 1;
}

.videoWrapper {
  & video {
    @media screen and (min-width: 601px) {
      max-width: 1400px;
      max-height: 70vh;
    }
  }
}

.logoContainerAboveContent {
  display: none;
}

.logoContainerDesktop {
  display: none;
}

.logoContainerMobile {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: flex-end;
  padding-left: var(--ds-spacing-2xs);
  padding-bottom: var(--ds-spacing-4xs);
  gap: var(--ds-spacing-3xs);
}

/*
  adding the aboveContentRight class here ensures the logo is always right-aligned on mobile when
  that option is selected in the CMS
  */
.logoContainerMobile.bottomRight,
.logoContainerMobile.aboveContentRight {
  justify-content: flex-end;
  padding-right: var(--ds-spacing-2xs);
  padding-left: 0;
}

.contentContainer,
.contentContainerVideo {
  margin: var(--ds-spacing-sm) var(--ds-spacing-xs);
  display: flex;
  flex-direction: column;
}

.productBadgeWrapper {
  display: flex;
  padding-bottom: var(--ds-spacing-2xs);
  gap: var(--ds-spacing-2xs);
}

.productBadgeWrapper.center,
.productBadgeWrapper.centerTop,
.productBadgeWrapper.centerBottom {
  justify-content: center;
}

.productBadgeWrapper.right {
  justify-content: flex-end;
}

.textWrapper {
  display: flex;
  flex-direction: column;
}

.textWrapper.center,
.textWrapper.centerTop,
.textWrapper.centerBottom {
  align-items: center;
  text-align: center;
}

.textWrapper.right {
  align-items: flex-end;
  text-align: right;
}

.title,
.bodyText {
  padding-bottom: var(--ds-spacing-2xs);
  margin: 0;
}

.title.light {
  color: var(--ds-color-text-inverse);
}

.title.dark {
  color: var(--ds-color-text-default);
}

.bodyText.light {
  color: var(--ds-color-text-inverse);
}

.bodyText.dark {
  color: var(--ds-color-text-default);
}

.callToActionWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: var(--ds-spacing-2xs);
}

.callToActionWrapper.center,
.callToActionWrapper.centerTop,
.callToActionWrapper.centerBottom {
  justify-content: center;
}

.callToActionWrapper.right {
  justify-content: flex-end;
}

.hotSpotGroup {
  position: absolute;
  aspect-ratio: 12 / 10;
  width: 100%;
  top: 0;

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }

  & .KISCardWrapper {
    display: flex;
    flex-direction: column;
    min-width: 280px;
    gap: var(--ds-spacing-2xs);
    border-radius: var(--ds-border-radius-xl);
    padding: var(--ds-spacing-sm);

    & .hotSpotActions {
      display: flex;
      gap: var(--ds-spacing-sm);
    }
  }

  & .defaultCardWrapper {
    display: flex;
    flex-direction: column;
    max-width: 160px;
    color: var(--ds-color-text-default);
    outline: 1px solid var(--ds-color-border-neutral-default);
    box-shadow: 0 4px 4px var(--ds-color-transparent-black-900);
    border-radius: var(--ds-border-radius-lg);
    padding: var(--ds-spacing-3xs);

    & > *:not(dialog) {
      margin: 0;
      margin-bottom: var(--ds-spacing-4xs);

      &:last-child {
        margin: 0;
      }
    }

    /* Read only product hot spot cards display only two children (name and arrow link), require slightly different styling on the wrapper */

    &:has(> :nth-child(2):last-child) {
      padding-bottom: 0;
    }

    & .textLinkWrapper {
      &:focus-within {
        box-shadow: 0 0 0 var(--ds-border-width-sm, 2px) var(--ds-color-focused-default)
      }

      & > a {
        color: var(--ds-color-text-default);
        margin: 0;

        &:focus, &:focus-visible {
          box-shadow: none;
        }
      }
    }

    & .iconLinkWrapper {
      position: absolute;
      right: 0;
      bottom: var(--ds-spacing-3xs);

      & > a {
        width: var(--ds-size-md);
        height: var(--ds-size-md);
        display: flex;
        justify-content: center;
        align-items: center;
      }

      /* Read only product hot spot cards display only two children (name and arrow link), require slightly different styling on the link */

      &:nth-child(2) {
        position: relative;
        bottom: unset;
        right: unset;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        & > a {
          margin-right: calc(-1 * var(--ds-spacing-3xs));
        }
      }
    }
  }

  @media screen and (min-width: 600px) {
    width: 45%;
    top: 50%;
    transform: translateY(-50%);
    aspect-ratio: 11 / 9;
  }

  @media screen and (min-width: 900px) {
    width: 56.25%;
    aspect-ratio: 12 / 7;
  }
}

/* This class exists only to support debugging hot spot placement. It adds no functionality or visuals. */
.hotSpotGroup.debug {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(10, 1fr);

  @media screen and (min-width: 600px) {
    grid-template-columns: repeat(11, 1fr);
    grid-template-rows: repeat(9, 1fr);
  }

  @media screen and (min-width: 900px) {
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(7, 1fr);
  }
}

@media (min-width: 601px) {
  .bannerWrapper {
    align-items: start;
    justify-content: center;
  }

  .bannerWrapper.center {
    align-items: center;
  }

  .bannerWrapper.right {
    align-items: end;
  }

  .bannerWrapper.centerTop {
    justify-content: start;
    align-items: center;
  }

  .bannerWrapper.centerBottom {
    justify-content: end;
    align-items: center;
  }

  .contentContainer {
    margin: 0 0 0 var(--ds-spacing-fluid-lg);
    position: absolute;
    width: clamp(238px, 50%, 440px);
  }

  .contentContainerVideo.standard {
    margin: 0 0 0 var(--ds-spacing-fluid-lg);
    position: absolute;
    width: clamp(238px, 50%, 273px);
  }

  .contentContainerVideo.tall {
    margin: 0 0 0 var(--ds-spacing-fluid-lg);
    position: absolute;
    width: clamp(238px, 50%, 268px);
  }

  .contentContainerVideo.xl {
    margin: 0 0 0 var(--ds-spacing-fluid-lg);
    position: absolute;
    width: clamp(238px, 50%, 275px);
  }

  .contentContainer.right,
  .contentContainerVideo.right {
    margin: 0 var(--ds-spacing-fluid-lg) 0 0;
  }

  .contentContainer.center,
  .contentContainerVideo.center {
    margin: 0;
  }

  .contentContainer.centerTop,
  .contentContainerVideo.centerTop {
    margin: var(--ds-spacing-fluid-lg) 0 0 0;
  }

  .contentContainer.centerBottom,
  .contentContainerVideo.centerBottom {
    margin: 0 0 var(--ds-spacing-fluid-lg) 0;
  }

  .logoContainerMobile {
    display: none;
  }

  /*
  adding the aboveContentLeft class here ensures the logo is always left-aligned on mobile when
  that option is selected in the CMS
  */
  .logoContainerAboveContent,
  .logoContainerAboveContent.aboveContentLeft {
    display: flex;
    padding-bottom: var(--ds-spacing-2xs);
  }

  .logoContainerAboveContent.center,
  .logoContainerAboveContent.centerTop,
  .logoContainerAboveContent.centerBottom {
    justify-content: center;
  }

  .logoContainerAboveContent.right {
    justify-content: flex-end;
  }

  .logoContainerDesktop {
    position: absolute;
    display: flex;
    padding-bottom: var(--ds-spacing-4xs);
    gap: var(--ds-spacing-2xs);
  }

  .logoContainerDesktop.bottomLeft {
    left: 0;
    bottom: 0;
    padding-left: var(--ds-spacing-2xs);
  }

  .logoContainerDesktop.bottomRight {
    right: 0;
    bottom: 0;
    padding-right: var(--ds-spacing-2xs);
  }
}

@media (min-width: 900px) {
  .contentContainer {
    width: clamp(345px, 48%, 638px);
  }

  .contentContainerVideo.standard {
    width: clamp(400px, 48%, 415px);
  }

  .contentContainerVideo.tall {
    width: clamp(400px, 48%, 418px);
  }

  .contentContainerVideo.xl {
    width: clamp(400px, 48%, 420px);
  }
}

@media (min-width: 1100px) {
  .contentContainerVideo.standard,
  .contentContainerVideo.xl {
    width: clamp(400px, 48%, 430px);
  }

  .contentContainerVideo.tall {
    width: clamp(400px, 48%, 435px);
  }
}

@media (min-width: 1300px) {
  .contentContainerVideo.standard {
    width: clamp(400px, 48%, 450px);
  }

  .contentContainerVideo.tall,
  .contentContainerVideo.xl {
    width: clamp(400px, 48%, 455px);
  }
}

@media (min-width: 1500px) {
  .contentContainerVideo.standard,
  .contentContainerVideo.tall,
  .contentContainerVideo.xl {
    width: clamp(345px, 48%, 638px);
  }
}
