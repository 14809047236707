@keyframes fadeInDrop {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.overlay {
  position: absolute;

  /*
  The first three buttons in a hot spot group animate in one at a time. Subsequent buttons animate all at once. Content
  editors are advised not to use more than three hotspots for other reasons, so the 'all at once' part is a fallback
  case that shouldn't be seen often.
  */

  & > button {
    --delayIndex: 3;
    opacity: 0;
    transform: translateY(-50px);

    &:nth-of-type(1) {
      --delayIndex: 0;
    }

    &:nth-of-type(2) {
      --delayIndex: 1;
    }

    &:nth-of-type(3) {
      --delayIndex: 2;
    }
  }

  &.animateEntry > button {
    animation: fadeInDrop 0.4s ease-out calc(0.2s + var(--delayIndex) * 0.2s) forwards;
  }
}
