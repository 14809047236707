.card {
  /*
  Adding display: block does not affect the dialog's visibility when closed.
  The browser uses the open flag to determine whether the dialog is displayed.
  Chromium browsers also add display: none but this breaks transitions on other properties.
  So we override it here.
 */
  display: block;
  left: 50%;
  transform: translateX(-50%);
  top: var(--top);
  position: absolute;
  color: var(--ds-color-layer-default);
  margin: 0;
  padding: 0;
  border: none;
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
  pointer-events: none;
  user-select: none;
  z-index: 2;

  &.skroll {
    /* skroll sm breakpoint is 580px */
    @media (--ds-breakpoint-sm) {
      left: var(--left);
      transform: unset;
    }
  }

  &.legacy {
    /* legacy components use 600px as the sm breakpoint */
    @media screen and (min-width: 600px) {
      left: var(--left);
      transform: unset;
    }
  }

  &:not([ open ]) > * {
    visibility: hidden;
  }
}

.card.open {
  opacity: 1;
  pointer-events: auto;
}
