.videoWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  /* prevent controls overlapping for 9 / 16 videos on small landscape viewports */
  min-width: 250px;

  &.fullscreen {
    background-color: var(--ds-color-layer-inverse);

    & .video {
      background-color: var(--ds-color-layer-inverse);
      /* if screen and video aspect ratio differ, full screen video can actually overflow the screen without max height and width */
      max-width: 100vw;
      max-height: 100vh;
    }
  }
}

.video {
  background-color: var(--ds-color-layer-inverse);
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100%;

  &::-webkit-media-controls {
    /* hides default media controls even in full screen */
    display: none;
  }
}
