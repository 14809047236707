.settings {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  align-items: center;
  gap: var(--ds-spacing-3xs);
}

.volume {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover .volumeSlider,
  &:focus-within .volumeSlider,
  & .volumeSlider:focus-within {
    /* the volume slider must be present in the tab order, even when not visible, for keyboard navigation to behave as expected in both directions */
    /* 'display: none' and 'visibility: hidden' both remove elements from the tab order, so are not a good fit here */
    /* instead, we make the controls 0px tall and transparent, then apply height and opacity when the volume button and/or slider itself get focus */
    height: 200%;
    opacity: 1;
  }
}

.volumeSlider {
  position: absolute;
  writing-mode: vertical-lr;
  direction: rtl;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: var(--ds-size-md);
  height: 0;
  opacity: 0;
  -webkit-appearance: slider-vertical;
  margin-bottom: var(--ds-spacing-3xs);
}
